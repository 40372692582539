<template>
  <nav-bar />
  <el-tabs v-model="activeName" class="tabs">
    <el-tab-pane label="열린 예측" name="open">
      <el-form-item>
        <el-select
          v-model="openEvent"
          @change="selectOpenedEvent"
          placeholder="종목 선택"
          style="width: 100px; margin-right: 5px"
        >
          <el-option label="축구" value="football" />
          <el-option label="해외축구" value="abroad-football" />
          <el-option label="야구" value="baseball" />
          <el-option label="해외야구" value="abroad-baseball" />
          <el-option label="농구" value="basketball" />
          <el-option label="해외농구" value="abroad-basketball" />
        </el-select>
        <el-date-picker
          :disabled="openDateDisabled"
          v-model="openSelectedDate"
          value-format="YYYY-MM-DD"
          :disabled-date="disableNotOpenedDate"
          type="date"
          placeholder="날짜 선택"
          style="margin-right: 5px"
        />
        <el-button type="info" :icon="Search" @click="getOpenedGame"
          >검색</el-button
        >
      </el-form-item>
      <el-table :data="openData" table-layout="auto" :style="{ width: '100%' }">
        <el-table-column align="center" prop="league" label="리그" />
        <el-table-column align="center" prop="homeTeam" label="홈" />
        <el-table-column align="center" prop="awayTeam" label="어웨이" />
        <el-table-column align="center" prop="home" label="홈 승" />
        <el-table-column align="center" prop="draw" label="무승부" />
        <el-table-column align="center" prop="away" label="어웨이 승" />
        <el-table-column align="center" label="결과">
          <template #default="scope">
            <el-select
              v-model="openData[scope.$index].result"
              placeholder="결과 선택"
              style="width: 100px"
            >
              <el-option label="홈 승" value="HOME" />
              <el-option label="무승부" value="DRAW" />
              <el-option label="어웨이 승" value="AWAY" />
              <el-option label="경기 취소" value="CANCEL" />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <el-popconfirm
        confirm-button-text="네"
        cancel-button-text="아니오"
        title="경기 결과가 올바르게 선택됐나요?"
        @confirm="pressCloseGame"
        width="250"
      >
        <template #reference>
          <el-button type="primary" style="width: 100%"
            >예측 결과 처리</el-button
          >
        </template>
      </el-popconfirm>
      <!-- <el-button type="danger" style="width: 48%;">예측 삭제</el-button> -->
    </el-tab-pane>

    <el-tab-pane label="닫힌 예측" name="close">
      <el-form-item>
        <el-select
          v-model="closeEvent"
          @change="selectClosedEvent"
          placeholder="종목 선택"
          style="width: 100px; margin-right: 5px"
        >
          <el-option label="축구" value="football" />
          <el-option label="해외축구" value="abroad-football" />
          <el-option label="야구" value="baseball" />
          <el-option label="해외야구" value="abroad-baseball" />
          <el-option label="농구" value="basketball" />
          <el-option label="해외농구" value="abroad-basketball" />
        </el-select>
        <el-date-picker
          :disable="closeDateDisabled"
          v-model="closeSelectedDate"
          value-format="YYYY-MM-DD"
          :disabled-date="disableNotClosedDate"
          @change="getClosedGame"
          type="date"
          placeholder="날짜 선택"
          style="margin-right: 5px"
        />
        <!-- <el-button type="info" :icon="Search" @click="getClosedGame">검색</el-button> -->
      </el-form-item>
      <el-table
        :data="closeData"
        table-layout="auto"
        :style="{ width: '100%' }"
      >
        <el-table-column align="center" prop="league" label="리그" />
        <el-table-column align="center" prop="homeTeam" label="홈" />
        <el-table-column align="center" prop="awayTeam" label="어웨이" />
        <el-table-column align="center" prop="home" label="홈 승" />
        <el-table-column align="center" prop="draw" label="무승부" />
        <el-table-column align="center" prop="away" label="어웨이 승" />
        <el-table-column align="center" prop="result" label="결과" />
      </el-table>
    </el-tab-pane>

    <el-tab-pane label="새로운 예측" name="new">
      <el-form-item>
        <el-select
          v-model="newEvent"
          placeholder="종목 선택"
          style="width: 100px; margin-right: 5px"
        >
          <el-option label="축구" value="football" />
          <el-option label="해외축구" value="abroad-football" />
          <el-option label="야구" value="baseball" />
          <el-option label="해외야구" value="abroad-baseball" />
          <el-option label="농구" value="basketball" />
          <el-option label="해외농구" value="abroad-basketball" />
        </el-select>
        <el-date-picker
          v-model="newSelectedDate"
          value-format="YYYY-MM-DD"
          :disabled-date="disablePastDate"
          type="date"
          placeholder="날짜 선택"
        />

        <el-button class="mt-4" style="margin-left: 5px" @click="addMatch">
          경기 추가
        </el-button>
      </el-form-item>
      <el-table :data="newData" table-layout="auto" :style="{ width: '100%' }">
        <el-table-column align="center" prop="league" label="리그">
          <template #default="league">
            <el-input
              v-model="newData[league.$index].league"
              placeholder="리그 입력"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="homeTeam" label="홈">
          <template #default="homeTeam">
            <el-input
              v-model="newData[homeTeam.$index].homeTeam"
              placeholder="홈 팀 입력"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="awayTeam" label="어웨이">
          <template #default="awayTeam">
            <el-input
              v-model="newData[awayTeam.$index].awayTeam"
              placeholder="어웨이 팀 입력"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" label="삭제">
          <template #default="remove">
            <el-button type="warning" @click.prevent="deleteRow(remove.$index)">
              삭제
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-popconfirm
        confirm-button-text="네"
        cancel-button-text="아니오"
        title="경기 정보가 올바르게 입력됐나요?"
        @confirm="clickCreateGame"
        width="250"
      >
        <template #reference>
          <el-button type="danger" style="width: 100%; margin-left: 0">
            새로운 게임 생성
          </el-button>
        </template>
      </el-popconfirm>
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import { ref, watch } from "vue";
import NavBar from "@/components/NavBar";
import { closeGame, createGame, getClosedDate, getGame, getOpenedDate } from "@/api/game";
import { ElNotification, ElLoading } from "element-plus";
import { Search } from "@element-plus/icons-vue";

const activeName = ref("open");

// 열린 예측
const openEvent = ref("");
const openDateDisabled = ref(true);
const openSelectedDate = ref("");
const openGameId = ref("");
const openData = ref(null);
const openDates = ref([]);

// 종목 선택 시 날짜 정보 가져옴
const selectOpenedEvent = async (event) => {
  openSelectedDate.value = "";
  openGameId.value = "";
  openData.value = null;
  try {
    let res = null;

    res = await getOpenedDate(event);
    openDates.value = res.data;
    openDateDisabled.value = false;
  } catch (e) {
    ElNotification({
      title: "서버 오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }
};

// 열리지 않은 날짜 비활성화
const disableNotOpenedDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return !openDates.value.includes(year + "-" + month + "-" + day);
};

// 게임 조회
const getOpenedGame = async () => {
  if (openSelectedDate.value === "") {
    ElNotification({
      title: "날짜 선택 안함",
      message: "날짜를 선택해주세요.",
      duration: 2000,
      type: "error",
    });
    return;
  }
  try {
    let res = null;

    res = await getGame(openEvent.value, openSelectedDate.value);
    openGameId.value = res.data.id;
    openData.value = res.data.matches;
  } catch (e) {
    ElNotification({
      title: "서버 오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }
};

// 예측 결과 처리
const pressCloseGame = async () => {
  if (openData.value == null) {
    ElNotification({
      title: "데이터 없음",
      message: "조회된 게임이 없습니다.",
      duration: 2000,
      type: "error",
    });
    return;
  }
  let results = [];
  let ok = true;
  openData.value.forEach((element) => {
    if (element.result == null) ok = false;
    results.push(element.result);
  });

  if (!ok) {
    ElNotification({
      title: "결과 선택 오류",
      message: "경기 결과를 모두 선택해주세요.",
      duration: 2000,
      type: "error",
    });
    return;
  }

  let loading = null;
  try {
    loading = ElLoading.service({
      lock: true,
      background: "rgba(255, 255, 255, 0.7)",
    });
    await closeGame(openEvent.value, openGameId.value, results);

    ElNotification({
      title: "성공",
      message: "성공적으로 경기가 닫혔습니다.",
      duration: 2000,
      type: "success",
    });
  } catch (e) {
    ElNotification({
      title: "오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }

  selectOpenedEvent(openEvent.value);
  loading.close();
};

// 닫힌 예측
const closeEvent = ref("");
const closeDateDisabled = ref(true);
const closeSelectedDate = ref("");
const closeData = ref(null);
const closeDates = ref([]);

// 닫힌 날짜 조회
const selectClosedEvent = async (event) => {
  closeSelectedDate.value = "";
  closeData.value = null;
  try {
    let res = null;

    res = await getClosedDate(event);
    closeDates.value = res.data;
    closeDateDisabled.value = false;
  } catch (e) {
    ElNotification({
      title: "서버 오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }
};

// 닫히지 않은 날짜 비활성화
const disableNotClosedDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return !closeDates.value.includes(year + "-" + month + "-" + day);
};

// 게임 조회
const getClosedGame = async () => {
  if (closeSelectedDate.value === "") {
    ElNotification({
      title: "날짜 선택 안함",
      message: "날짜를 선택해주세요.",
      duration: 2000,
      type: "error",
    });
    return;
  }
  try {
    let res = null;

    res = await getGame(closeEvent.value, closeSelectedDate.value);
    
    closeData.value = res.data.matches;
  } catch (e) {
    ElNotification({
      title: "서버 오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }
};

// 새 예측 생성
const newEvent = ref("");
const newSelectedDate = ref("");
const newData = ref([
  {
    league: "",
    homeTeam: "",
    awayTeam: "",
  },
]);

watch(newEvent, () => {
  newData.value = [{
    league: "",
    homeTeam: "",
    awayTeam: "",
  }]
})

const addMatch = () => {
  newData.value.push({
    league: "",
    homeTeam: "",
    awayTeam: "",
  });
};

const clickCreateGame = async () => {
  if (newEvent.value === "" || newSelectedDate.value === "") {
    ElNotification({
      title: "미입력된 값",
      message: "종목과 날짜를 입력해주세요.",
      duration: 2000,
      type: "error",
    });
    return;
  }

  let ok = true;
  newData.value.forEach((element) => {
    if (
      element.league === "" ||
      element.homeTeam === "" ||
      element.awayTeam === ""
    )
      ok = false;
  });

  if (!ok) {
    ElNotification({
      title: "결과 선택 오류",
      message: "경기 결과를 모두 선택해주세요.",
      duration: 2000,
      type: "error",
    });
    return;
  }

  try {
    await createGame(newEvent.value, newSelectedDate.value, newData.value);

    ElNotification({
      title: "게임 생성 완료",
      message: "게임이 생성되었습니다.",
      duration: 2000,
      type: "success",
    });
  } catch (e) {
    ElNotification({
      title: "서버 오류",
      message: e.response.data,
      duration: 2000,
      type: "error",
    });
  }
};

const disablePastDate = (date) => {
  return date.getTime() < Date.now() + 86400000;
};

const deleteRow = (index) => {
  newData.value.splice(index, 1);
};
</script>

<style scoped>
.tabs {
  margin-top: 5vh;
  width: 60%;
  padding-left: 20%;
  min-width: 600px;
}
</style>
